import React, { useState, useEffect } from "react"
import {Form} from "react-bootstrap"
import {calculateMonthlyPayment, CalculatorFields} from "../../../../calculator/mortgage"
import {addCommas, numericWithDecimal, removeNonNumeric} from "../../../common/utils"


const MortgageCalculator = (props) => {

    const[error_msg, setErrorMsg] = useState('');

    const [calculator_value, setCalculatorValue] = useState({'loan_amount':((75/100) * props.calc_price).toFixed(2), 'purchase_price': props.calc_price, 'interest_rate':4.30, 'amortization': '30', 'down_payment':((25/100) * props.calc_price)});

    

    const handleChange = (event, field_name) => {
        
        console.log("down_payment", parseFloat(numericWithDecimal(document.getElementById("down_payment").value)), parseFloat(numericWithDecimal(document.getElementById("loan_amount").value)))

        if(parseFloat(numericWithDecimal(document.getElementById("down_payment").value)) > parseFloat(numericWithDecimal(document.getElementById("loan_amount").value))){
            setErrorMsg("Down payment should not be greater than price.")
        } else {
            setErrorMsg("");

            
        }

        if(event.target.name === "interest_rate"){
            setCalculatorValue({...calculator_value, [event.target.name]: event.target.value > CalculatorFields.InterestMax ? CalculatorFields.InterestMax : numericWithDecimal(event.target.value)})
        } else{
            if(event.target.name === "amortization"){
            if(parseInt(event.target.value) > 35){
            
            } else{
                setCalculatorValue({...calculator_value, [event.target.name]: numericWithDecimal(event.target.value)})
            }
            } else{
                setCalculatorValue({...calculator_value, [event.target.name]: numericWithDecimal(event.target.value)})
            }
        }

    }

    var mortgage_monthly_payment =  parseFloat(calculateMonthlyPayment(calculator_value.loan_amount,calculator_value.interest_rate,calculator_value.down_payment,calculator_value.amortization)).toFixed(2);

    return (
        <React.Fragment>
            <Form className="form-wrapper">
                {error_msg && <div className="alert alert-danger col-12 p-3">{error_msg}</div>}
                <Form.Group>
                    <Form.Label>Price</Form.Label>
                    <span className="currency">£</span>
                    <Form.Control type="text" name="loan_amount" id="loan_amount" value={addCommas(calculator_value.loan_amount)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>deposit</Form.Label>
                    <span className="currency">£</span>
                    <Form.Control type="text"  name="down_payment" id="down_payment" value={addCommas(calculator_value.down_payment)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax} />
                </Form.Group>

                <Form.Group className="repayment-term">
                    <Form.Label>repayment term</Form.Label>
                    <Form.Control className="sml_wdth" type="text" name="amortization" value={removeNonNumeric(calculator_value.amortization)} onChange={handleChange} maxLength={CalculatorFields.YearInputMax}/>
                    <span className="currency">years</span>     
                </Form.Group>

                <Form.Group className="interest-rate">
                    <Form.Label>interest rate</Form.Label>
                    <Form.Control className="sml_wdth" type="text" name="interest_rate" value={numericWithDecimal(calculator_value.interest_rate)} onChange={handleChange} maxLength={CalculatorFields.InterestInputMax}/>
                    <span className="currency">%</span>
                </Form.Group>
            </Form>
            <div className="form-details">
                <div className="rate">
                    £{parseInt(mortgage_monthly_payment) > 0 ? addCommas(mortgage_monthly_payment) : 0} <span className="sm-text">per month</span>
                </div>
                {/* <div className="help">
                    Need help? Speak with our <Link to="#" className="broker">Mortgage Broker</Link>
                </div> */}
            </div>
        </React.Fragment>
    );
};
export default MortgageCalculator;
