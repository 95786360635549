import React, { useState, useRef, useEffect } from "react"
import {AuctionsPropertyPage} from "../../queries/common_use_query"
import Counter  from '../Home/Counter/Counter.js'

// Header component

const BannerBottom = (props) => {
    const { loading:article_loading, error, data:article_data } = AuctionsPropertyPage();

    var article_page = article_data?.articles?.length > 0 && article_data?.articles[0];
    var counter_module = article_page?.Modules?.filter(Module => Module.__typename === "ComponentComponentBannerBottom");

    return (
        <React.Fragment>
            {/* ======= Banner Bottom ======= */}
            {
                counter_module?.length > 0 && counter_module?.map((Module, i) =>{
                    return(
                        <Counter Module={Module} Layout={article_page.Layout} />
                    )
                })
            }
            {/* ======= Banner Bottom ======= */}
        </React.Fragment>
    )
}
export default BannerBottom
