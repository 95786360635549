import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"

import { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr, getSizeStr, toTitleCase } from "./utils"

export const propertyDetH1 = (property) => {
    // [property type] [action] with [bedroom] bedrooms in [address] at [price offered]

    var h1 = "";
    var bed_room = property.bedroom === 0 ? "studio" : (property.bedroom > 1 ? property.bedroom+" bedrooms" : property.bedroom+" bedroom");
    
    if(property.department){
        var size = property?.floorarea_min;
        var floorarea_type = property?.floorarea_type;

        if(property?.floorarea_max){
            size = property.floorarea_max;
        }
        if(property?.floorarea_min && property?.floorarea_max){
            size = "from "+property.floorarea_min+" to "+property.floorarea_max;
        }

        h1 += property.building ? toTitleCase(property.building.toString().replace(/,/g, ', ').replace(/-/g, ' '))+" " : "";

        h1 += property.status ? property?.status?.toLowerCase() : "";

        h1 += property.bedroom ? " with "+bed_room : "";

        if(property.department === "auction_residential" || property.department === "auction_commercial"){
            h1 += " through auction";
        }

        h1 += property.display_address ? " in "+property.display_address.toString() : "";

        if(property.department === "residential"){
            h1 += property.price ? " at £"+property.price.toLocaleString() : "";
        }

        if(property.department === "commercial"){
            h1 += size ? " "+size+" "+floorarea_type : "";
        }

        if(property.department === "auction_residential" || property.department === "auction_commercial"){
            h1 += size ? " "+size+" "+floorarea_type : "";
        }

    }

    return h1;
}

export const propertyDetDesc = (property) => {
    // View the details of [property type] [action] with [bedroom] bedrooms in [address] at [price offered]. Book a viewing to get assistance in finding the right [property type] for you.

    var bed_room = property.bedroom === 0 ? "studio" : (property.bedroom > 1 ? property.bedroom+" bedrooms" : property.bedroom+" bedroom");

    var desc = "View the details of "+property?.building?.toString()+" "+property?.status?.toLowerCase()+" with "+bed_room+" in "+property.display_address+" at £"+property?.price?.toLocaleString()+". Book a viewing to get assistance in finding the right "+property?.building?.toString()+" for you.";

    var size = property?.floorarea_min;
    if(property?.floorarea_max){
        size = property.floorarea_max;
    }
    if(property?.floorarea_min && property?.floorarea_max){
        size = "from "+property.floorarea_min+" to "+property.floorarea_max;
    }

    if(property.department === "commercial"){
        desc = "View the details of "+property?.building?.toString().replace(/-/g, ' ')+" "+property?.status?.toLowerCase()+" in "+property.display_address+" "+size+" "+property?.floorarea_type+" with Strettons. Book a viewing to get assistance in finding the right "+property.building.toString().replace(/-/g, ' ')+" for you.";
    }
    if(property.department === "auction_residential" || property.department === "auction_commercial"){
        desc = "View the details of "+property?.building?.toString().replace(/-/g, ' ')+" for sale in "+property.display_address+" available for auction with Strettons. Book a viewing to get assistance in securing the right "+property?.building?.toString()+" in auctions.";
    }


    return desc;
}

export const propertyDetImage = (property) => {

    const firstImg = property?.images?.[0]?.url ?? property?.images?.[0]?.srcUrl;
    var image = '';
    let processedImages = JSON.stringify({});
    const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev";

    if (property?.imagetransforms?.images_Transforms) {
        processedImages = property?.imagetransforms?.images_Transforms;
        var resized_images = JSON.parse(processedImages);
        const SrcCftle = property?.images && property?.images.length > 0 && property?.images[0]?.url?.substring(property?.images && property?.images.length > 0 && property?.images[0]?.url?.indexOf(STAGE_ENV));
        const ProcessedUrl = resized_images[SrcCftle] && resized_images[SrcCftle]?.jpg ? resized_images[SrcCftle]?.jpg['400x260'] :
                             resized_images[SrcCftle]?.jpeg ? resized_images[SrcCftle]?.jpeg['400x260'] :
                             resized_images[SrcCftle]?.png ? resized_images[SrcCftle]?.png['400x260'] :
                             property?.images && property?.images.length > 0 && property?.images[0].url;
        image = ProcessedUrl && ProcessedUrl !== undefined ? ProcessedUrl : firstImg;
    }

    return image;
}