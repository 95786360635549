import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import infoimg01 from "../../../images/propertydetails/info-img01.png"

import {People_NegoID} from "../../../queries/common_use_query"
import {ImageModule} from "../../../modules/Image_Module"
import {CustomLinks, getOffset} from "../../common/utils"
import {gtag_report_conversion} from "../../common/pageUtils"

import AuctionNegoItem from "./AuctionNegoItem"


const EnquirySec = (props) => {
    
    var split_nego_id = props?.propertyData?.crm_negotiator_id && props?.propertyData?.crm_negotiator_id?.split('/');

    console.log("split_nego_id", split_nego_id, split_nego_id && split_nego_id.length);

    // const { loading, error, data:nego_data } = props?.propertyData && split_nego_id ? People_NegoID(split_nego_id[0]) : People_NegoID(props?.propertyData?.crm_negotiator_id);

    return (
        <React.Fragment>
            {(props?.propertyData?.status !== "Sold" || split_nego_id && split_nego_id.length > 0) &&
                <div className={`enquiry ${props?.propertyData?.auctionType === "past" ? "sticky_enquire" : ""} ${props.stickyCls}`}>
                {
                props?.propertyData?.status !== "Sold" && <>
                <h2>Enquire about  <br>
                </br> {props?.propertyData?.display_address}</h2>
                
                    <div className="buttons">
                        {
                            props?.propertyData?.extra?.lotPdfUrl && 
                            <a href={props?.propertyData?.extra?.lotPdfUrl} target={"_blank"} className="btn btn-primary">
                                Download PDF
                            </a>
                        }

                        {
                            props?.globalModule?.Register_To_Bid && 
                            <a href={props?.globalModule?.Register_To_Bid} target={"_blank"} className="btn btn-primary" onClick={()=> gtag_report_conversion()}>
                                register to bid
                            </a>
                        }
                        
                        {/* {
                            props?.globalModule?.Make_An_Offer && 
                            <a href={props?.globalModule?.Make_An_Offer} target={"_blank"} className="btn btn-primary">
                                make an offer  
                            </a>
                        } */}
                        

                        {
                            props?.propertyData?.extra?.legalDocumentUrl &&
                            <a href={props?.propertyData?.extra?.legalDocumentUrl} target={"_blank"} className="btn btn-primary">
                                view legal documents 
                            </a>
                        }

                        {/* {
                            props?.globalModule?.Purchase_Legal_Summary &&
                            <a href={props?.globalModule?.Purchase_Legal_Summary} target={"_blank"} className="btn btn-primary">
                                purchase legal summary 
                            </a>
                        } */}
                    </div>
                    <div className={`contact-person`}></div>
                </>
                }

                {split_nego_id?.length > 0 && split_nego_id.map((item, index) => {
                    return(
                        <AuctionNegoItem item={item} />
                    )}
                )}
                
                {/* {
                    nego_data && nego_data?.peoples?.length > 0 && nego_data?.peoples?.map((item, index) =>{
                        return(
                            <div className={`contact-person ${index > 0 ? "" : "mt-0"}`}>
                                
                                <div className="contact-img">
                                    <Link to={CustomLinks.our_people+CustomLinks.find_team+'/'+item.URL}>
                                        <picture>
                                            <ImageModule ImageSrc={item.Image} />
                                        </picture>
                                    </Link>
                                </div>
                                <div className="contact-info">
                                    <h3><Link to={CustomLinks.our_people+CustomLinks.find_team+'/'+item.URL}>{item.Name}</Link></h3>
                                    <a href={`tel:${item.Phone_Number}`} className="tel">{item.Phone_Number}</a>
                                </div>
                                
                            </div>
                        )
                    })
                } */}
                
            </div>
            }
            
        </React.Fragment>
    );
};
export default EnquirySec;

