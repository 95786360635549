import React, { useEffect, useState } from "react"
import "./ContactStickyNav.scss"
import { isMobile } from "react-device-detect"
import useWindowSize from "../../common/useWindowSize"

const ContactStickyNav = props => {
  const [scroll, setScroll] = useState(false)

  const { width } = useWindowSize()

  useEffect(() => {
    try {
      window.addEventListener("scroll", () => {
        if (width < 1200) {
          setScroll(window.scrollY > 30)
        } else if (width > 1200 && width < 1400) {
          setScroll(window.scrollY > 70)
        } else {
          setScroll(window.scrollY > 120)
        }
      })
    } catch {}
  }, [width])

  var contactDetails = props?.propertyData?.crm_negotiator_id[0]
  return (
    <div
      className={
        scroll ? "d-md-none contact-sticky-mobile-container" : "d-none"
      }
    >
      {contactDetails && (
        <div>
          <p className="name">{contactDetails?.name}</p>
          <a className="phone" href={"tel:" + contactDetails?.telephone} >
            +44 {contactDetails?.telephone}{" "}
          </a>
        </div>
      )}
    </div>
  )
}

export default ContactStickyNav
