import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Select } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import MortgageCalculator from "./Sections/Mortgage"
import StampDuty from "./Sections/StampDuty"

import "./Calculator.scss"
// Header component

const Calculator = (props) => {

    return (
        <React.Fragment>
            <section className="calculator-section">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                                <div className="calculator">
                                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                        <Tab eventKey="home" title="Mortgage Calculator">
                                            <MortgageCalculator calc_price={props.calc_price} />
                                        </Tab>


                                        <Tab eventKey="profile" title="Stamp Duty Calculator">
                                            <StampDuty calc_price={props.calc_price} property_type={props.property_type} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default Calculator;
