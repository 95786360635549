import React from "react"
import "./EnquirySticky.scss"
import { gtag_report_conversion } from "../../common/pageUtils"

const EnquirySticky = () => {
  return (
    <div className=" d-md-none enquiry-now-sticky">
      <a
        href="javascript:void(0)"
        onClick={() => gtag_report_conversion()}
        className="btn enquireNow"
      >
        Enquire Now
      </a>
    </div>
  )
}

export default EnquirySticky
