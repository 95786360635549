import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./Joint.scss"
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery';
import dateFormat from "dateformat";
import ScrollAnimation from "react-animate-on-scroll"

// Header component

const Joint = (props) => {
    // function ContextAwareToggle({ children, eventKey, callback }) {
    //     const currentEventKey = useContext(AccordionContext)

    //     const decoratedOnClick = useAccordionToggle(
    //         eventKey,
    //         () => callback && callback(eventKey)
    //     )

    //     const isCurrentEventKey = currentEventKey === eventKey

    //     console.log("isCurrentEventKey", isCurrentEventKey);

    //     return (
    //         <button
    //             type="button"
    //             className={
    //                 isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
    //             }
    //             onClick={decoratedOnClick}
    //         >
    //             {children}
    //             <i
    //                 className={
    //                     isCurrentEventKey ? "minus-circle " : "plus-circle"
    //                 }
    //             ></i>
    //         </button>
    //     )
    // }

    //console.log("accord", item.Value);

    useEffect(() => {
        $(".card-header").on('click', function () {
            $(this).next('.accordion').toggleClass('hide');
            $('.inactive_card', this).toggleClass('active_card');
            $('i', this).toggleClass('plus-circle');
            $('i', this).toggleClass('minus-circle');
        })
    }, [])


    var site_heading_text_value = "";

    return (
        <React.Fragment>
            <section className="joint-section">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                                <div className="joint-wrapper">
                                    <div className="joint-block">
                                        <div className="accordion-section">
                                            <Accordion>
                                                {
                                                    props?.accord_accomada?.length > 0 && props?.accord_accomada?.filter(item => item.Name !== "Has EPC").map((item, index) => {

                                                        var acc_value = item.Value.replace(/\r\n/g, '<br>');
                                                        const AccClassName = item.Name.replace(/[\s\/]+/g, '-').replace(/[^\w-]/g, '').toLowerCase();

                                                        if (item.Name === "Site Heading Text") {
                                                            site_heading_text_value = item.Value.replace(/\r\n/g, '<br>');
                                                        }
                                                        if ((item.Name).indexOf('Bullet') && item.Name !== "Site Heading Text") {
                                                            return (
                                                                <Card>
                                                                    {item.Name !== 'Vacant/Let' ?
                                                                    <div className="accordion-header card-header">
                                                                        {/* <ContextAwareToggle eventKey={index.toString()}>
                                                                            {item.Name}
                                                                        </ContextAwareToggle> */}
                                                                        <button type="button" className={"inactive_card"} >
                                                                            {item.Name}
                                                                            <i className={"minus-circle"}></i>
                                                                        </button>
                                                                    </div>
                                                                    : "" }

                                                                    <Accordion>
                                                                        <div className={`accordion-card accordion-card-first card-body card-${AccClassName}`}>
                                                                            {item.Name === "Accommodation" && (
                                                                                props?.accomada_header_notes.length > 0 && <p>{HTMLReactParser(props?.accomada_header_notes[0].Value)}</p>
                                                                            )
                                                                            }
                                                                            <p>{HTMLReactParser(acc_value)}</p>
                                                                            <p>{item.Name === "Site Area:" && HTMLReactParser(site_heading_text_value)}</p>
                                                                        </div>
                                                                    </Accordion>
                                                                </Card>
                                                            )
                                                        }

                                                    })
                                                }

                                                {(props?.extra?.viewingTimeDescription || props?.extra?.viewingTimes?.length > 0) &&
                                                    <Card>
                                                        <div className="accordion-header card-header">
                                                            <button type="button" className={"inactive_card"} >
                                                                Viewings
                                                                <i className={"minus-circle"}></i>
                                                            </button>
                                                        </div>

                                                        <Accordion>
                                                            {props?.extra?.viewingTimeDescription &&
                                                                <div className="accordion-card accordion-card-first card-body">
                                                                    <p>{HTMLReactParser(props?.extra?.viewingTimeDescription.replace(/\r\n/g, '<br>'))}</p>
                                                                </div>
                                                            }

                                                            {props?.extra?.viewingTimes?.length > 0 &&
                                                                <div className="accordion-card accordion-card-first card-body">
                                                                    <ul className="viewing-times">
                                                                        {props?.extra?.viewingTimes.map((item, index) => {

                                                                            var start_view_time = dateFormat(item.StartDate, "dddd ddS mmmm") + " at " + dateFormat(item.StartDate, "HH:MMtt");

                                                                            var end_view_time = dateFormat(item.EndDate, "HH:MMtt");

                                                                            return (
                                                                                <li><p>{start_view_time + " - " + end_view_time}</p></li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </Accordion>
                                                    </Card>}

                                                {props?.extra?.addendum &&
                                                    <Card>
                                                        <div className="accordion-header card-header">
                                                            <button type="button" className={"inactive_card"} >
                                                                Addendum
                                                                <i className={"minus-circle"}></i>
                                                            </button>
                                                        </div>

                                                        <Accordion>
                                                            <div className="accordion-card accordion-card-first card-body">
                                                                <p>{HTMLReactParser(props?.extra?.addendum.replace(/\r\n/g, '<br>'))}</p>
                                                            </div>
                                                        </Accordion>
                                                    </Card>}
                                            </Accordion>
                                        </div>


                                        <div className="joint">
                                            {props?.extra && props?.extra?.jointAuctioneer && props?.extra?.jointAuctioneer.length > 0 && <>
                                                <h2>Joint Auctioneer:</h2>
                                                {props?.extra?.jointAuctioneer.map((jc, index) => (
                                                    <div className="joint-info">
                                                        {jc?.LogoUrl &&
                                                            <div className="logo">
                                                                <picture>
                                                                    <img src={jc?.LogoUrl} alt={jc?.DisplayName} />
                                                                </picture>
                                                            </div>
                                                        }
                                                        <div className="contact-info">
                                                            <div className="tel">
                                                                {jc?.DisplayName}
                                                            </div>
                                                            {/* <div className="mail">
                                                            <a href="mailto: chris.baker@lambertsurv.co.uk"> chris.baker@lambertsurv.co.uk</a>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                ))

                                                }

                                            </>
                                            }

                                            {
                                                props?.globalModule?.Notice_Bidder_Content &&
                                                HTMLReactParser(props?.globalModule?.Notice_Bidder_Content)
                                            }
                                        </div>
                                    </div>

                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default Joint;

