
import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import infoimg01 from "../../../images/propertydetails/info-img01.png"

import {People_NegoID} from "../../../queries/common_use_query"
import {ImageModule} from "../../../modules/Image_Module"
import {CustomLinks, getOffset} from "../../common/utils"


const NegoDetails = (nego_id) =>{
    const { loading, error, data } = People_NegoID(nego_id.item);
    // console.log("nego_info ==>", data);
    //return data;

    //console.log("nego_id", nego_id.item);
    return(
        loading ? '' :
        data && data?.peoples?.length > 0 ? 
            data?.peoples?.map((item, index) =>{
                return(
                    <div className={`contact-person mb-4 ${index > 0 ? "" : "mt-0"}`}>
                        
                        <div className="contact-img">
                            <Link to={CustomLinks.our_people+CustomLinks.find_team+'/'+item.URL+"/"}>
                                <picture>
                                    <ImageModule ImageSrc={item.Image} altText={item.Name+ " - Strettons"}/>
                                </picture>
                            </Link>
                        </div>
                        <div className="contact-info">
                            <h3><Link to={CustomLinks.our_people+CustomLinks.find_team+'/'+item.URL+"/"}>{item.Name}</Link></h3>
                            <a href={`tel:${item.Phone_Number}`} className="tel">{item.Phone_Number}</a>
                        </div>
                        
                    </div>
                )
            })
        : ''
    )
}

export default NegoDetails;