import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';

import {CustomLinks, getOffset} from "../../common/utils"

import Joint from "../Joint/Joint";
import EnquirySection from "./EnquirySection";
import "./Description.scss";
// Header component

const Description = (props) => {
  const stickyRef = useRef();
  const [readMore, setReadMore] = useState(false);
  const [stickyCls, setStickyCls] = useState('');

  useEffect(() => {
    window.addEventListener("scroll", windowScroll);
    return () => window.removeEventListener("scroll", windowScroll);
  },[])

  //sticky
  const windowScroll = () => {
      const top = getOffset(stickyRef.current).top;
      const bottomSticky = getOffset(stickyRef.current).top + 2300;
      // const top = 650;
      // const bottomSticky = 1500;

      //console.log("top", top, getOffset(stickyRef.current).top, window.pageYOffset);

      if(window.pageYOffset > top){
        setStickyCls('is-sticked');
      } else {
        setStickyCls('');
      }
  }

  

  var accord_accomada  = props?.propertyData?.extra?.lotData?.filter(item => (item.ShowOnWeb === true && (item.Value !== "False" && item.Value !== "") && item.Name !== "Site Heading Text") || item.Name === "Site Heading Text" && (item.Value !== "False" && item.Value !== ""));


  var accomada_header_notes = props?.propertyData?.extra?.lotData?.filter(item => item.Name === "Accommodation Header Notes" && (item.Value !== "False" && item.Value !== ""));


  var description = props?.propertyData?.extra?.marketing?.text?.length > 0 && props?.propertyData?.extra?.marketing?.text.filter(item => item.content !== null && (item.key === "description" || item.key === "location" || item.key === "accommodation"));

  //console.log("accord_accomada",  accord_accomada?.length > 0 && accord_accomada?.filter(item => (item.Name).indexOf('Bullet')))

  var prop_desc = props?.propertyData?.description ? props?.propertyData?.description?.replace(/<colour="0,15,100,0">/g, '') : '';

  return (
    <React.Fragment>
      {
        props?.propertyData &&
        <section className={`description ${props?.propertyData?.department === "auction_commercial" || props?.propertyData?.department === "auction_residential" ? "auction_desc" :""}`} id="property-details-description" ref={stickyRef}>
          <Container>
            <Row>
              <Col>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="property-wrapper">

                    <div className="property-details no_bdr">
                      {
                        props?.propertyData?.department === "commercial" ? 
                        <>
                          {
                            description.length > 0 ?
                            <>
                              <h2>Property Description</h2>
                              <div className="property_desc">
                                {
                                  description.map((item, index) => {
                                    if(index < 2){
                                      return(
                                        <p key={index}>{HTMLReactParser(item.content.replace(/\n\n/g,'<br><br>').replace(/\r\n/g,'<br>'))}</p>
                                      )
                                    }                                    
                                  })                                  
                                }
                                {!readMore && description.length > 2 && <><a href={"javascript:;"} className="readmore_link readmore" onClick={() => setReadMore(true)}> + Read More</a></>}

                                {
                                  readMore && description.map((item, index) => {
                                    if(index >= 2){
                                      return(
                                        <p key={index}>{HTMLReactParser(item.content.replace(/\n\n/g,'<br><br>').replace(/\r\n/g,'<br>'))}</p>
                                      )
                                    }                                    
                                  })     
                                }
                                {readMore && <><a href={"javascript:;"} className="readless_link readmore" onClick={() => setReadMore(false)}> - Read Less</a></>}
                              </div>                              
                            </>
                            :
                              prop_desc && 
                              <>
                                <h2>Property Description</h2>
                                <div className="property_desc desc_chng">
                                  { !readMore && 
                                    prop_desc.length > 500 ? 
                                    <>
                                      <p>{HTMLReactParser(prop_desc.substr(0, 500))}</p>

                                      <a href={"javascript:;"} className="readmore_link readmore" onClick={() => setReadMore(true)}> + Read More</a>
                                    </>
                                    :
                                    <>
                                      <p>{HTMLReactParser(prop_desc)}</p>
                                    </>
                                  }
                                  {readMore && 
                                    <>
                                      <p>{HTMLReactParser(prop_desc)}</p>
                                      <a href={"javascript:;"} className="readless_link readmore" onClick={() => setReadMore(false)}> - Read Less</a>
                                    </>
                                  }   
                                </div>
                              </>
                          }
                        </>
                        : 
                        prop_desc && <>
                          <h2>Property Description</h2>
                          <div className="property_desc desc_chng">
                            { 
                              prop_desc.length > 500 ? (
                                !readMore ? 
                                <>
                                  <p>{HTMLReactParser(prop_desc.substr(0, 500))}</p>
                                  <a href={"javascript:;"} className="readmore_link readmore" onClick={() => setReadMore(true)}> + Read More</a>                                
                                </> 
                                :
                                <>
                                  <p>{HTMLReactParser(prop_desc)}</p>
                                  <a href={"javascript:;"} className="readless_link readmore" onClick={() => setReadMore(false)}> - Read Less</a>
                                </>   
                              )                        
                              :
                              <>
                                <p>{HTMLReactParser(prop_desc)}</p>
                              </>
                            }
                              
                          </div>
                        </>
                      }

                      {
                        accord_accomada?.filter(item => !item.Name.indexOf('Bullet'))?.length > 0 &&
                          <div className="property-listing full_wdth">
                            <h2>Property Features</h2>
                              <ul className="list">
                                  {accord_accomada?.filter(item => !item.Name.indexOf('Bullet')).map((item, index)=> {

                                    var acc_value = item.Value.replace(/\r\n/g,'<br>');
                                    if(acc_value.trim()){
                                      return(
                                        <li key={index}>{HTMLReactParser(acc_value)}</li>
                                      )
                                    }
                                  })
                                  }
                              </ul>
                          </div>
                      }
                      
                      { props?.propertyData?.extra?.accommodationTable &&
                            <div className="accommodation-table-wrap">
                              <h2>Tenancy Schedule</h2>
                              <div class="accommodation-table-container">
                                {HTMLReactParser(props?.propertyData?.extra?.accommodationTable)}
                              </div>
                            </div>
                      }

                      {
                        (props?.propertyData?.department === "auction_residential" || props?.propertyData?.department === "auction_commercial") && accord_accomada.length > 0 ? 
                        <Joint accord_accomada={accord_accomada} globalModule={props.globalModule} extra={props?.propertyData?.extra} accomada_header_notes={accomada_header_notes}  /> : ""
                      }
                    </div>
                    

                    {
                      props?.propertyData &&
                      (props?.propertyData?.department === "residential" || props?.propertyData?.department === "commercial") ?

                        props?.propertyData?.accommodation_summary &&
                        props?.propertyData?.accommodation_summary?.length > 0 &&
                        <div className={(props?.propertyData?.description || description.length > 0) ? "property-listing" : (props?.propertyData?.long_description ? "property-listing" : "property-listing w-100")}>
                          <h2>
                            {props?.propertyData?.department === "commercial" ? "Property Features" : " "}
                          </h2>
                          
                          <ul className="list">
                            {
                              props?.propertyData?.accommodation_summary?.map((summary, i) => {
                                if(summary){
                                  return (
                                    <li key={i}>{HTMLReactParser(summary)}</li>
                                  )
                                }
                              })
                            }
                          </ul>
                        </div>
                      : 
                      <EnquirySection globalModule={props.globalModule} propertyData={props?.propertyData} stickyCls={stickyCls} />
                    }               
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </section>
      }      
    </React.Fragment>
  );
};
export default Description;
