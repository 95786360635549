import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import axios from "axios"
import HTMLReactParser from 'html-react-parser';
import SimilarPropertiesNegoDetails from "./SimilarPropertiesNegoDetails"
import {ImageModule} from "../../../modules/Image_Module";
import "./SimilarProperties.scss"
import NoImage from "../../../images/no-image.png"
import { toTitleCase } from "../../property-search/utils"
import {SimilarPropertiesAuctions} from "../../../queries/common_use_query"

const settings = {
    dots: false,
    speed: 800,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    margin:10,
    responsive: [
      {
        breakpoint: 3020,
        settings: {
          arrows: false,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          dots: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
};

const SimilarProperties = (props) => {

    const propertyStatus = props?.propertyData?.status;
    const area = props?.area;
    const department = props?.department;
    const auctionType = props?.propertyData?.auctionType;
    //
    const [propItems, setPropItems] = useState([])

    const {loading, error, data} = SimilarPropertiesAuctions(area, department, auctionType)

    const getitems = async url => {
        try {
        const { data } = await axios.get(url, {})
        // 
        // console.log("PropertySimilar", data)
        setPropItems(data)
        } catch (error) {
        // console.error(error)
        }
    }

    useEffect(() => {
        // 
        if(props.department === "auction_residential" || props.department === "auction_commercial"){
            var prop_items = [];
            data?.refer_auctions?.length > 0 && data.refer_auctions.map((item) => prop_items.push(item))
            setPropItems(prop_items)
        } else{
            let url =
            process.env.GATSBY_STRAPI_SRC +
            "/stb-lists/item/Property-details?pid=" +
            props?.propertyData?.id

            getitems(url)
        }
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [props.area, data]);
    //


    function handleResize() {
        setTimeout(() => {
        if(768<=window.innerWidth ) {
            const collection = document.getElementsByClassName("equalHeights");
            const commercial_title = document.getElementsByClassName("address_title");
            var maxHeight = 0;
            var commercialTitleHeight = 0;
            for (var i=0; i < collection.length; i++) {
                if(collection[i].getAttribute("style")) {
                    collection[i].style.removeProperty("height");
                }
                maxHeight = Math.max(maxHeight, collection[i].offsetHeight);
                if(0<commercial_title.length) {
                    if(commercial_title[i].getAttribute("style")) {
                        commercial_title[i].style.removeProperty("height");
                    }
                    commercialTitleHeight = Math.max(commercialTitleHeight, commercial_title[i].offsetHeight);
                }
            }
            for (var i=0; i < collection.length; i++) {
               collection[i].style.height = maxHeight+'px';
               if(0<commercial_title.length) {
                commercial_title[i].style.height = commercialTitleHeight+'px';
               }
            }
        }
      }, 150);
      }

    const propStatus = propItems.map(res => {
        return res
    })

    const auction_Prop = propStatus.filter(val => {
        var data;
        switch (val.status) {
          case "Withdrawn": if (val?.status !== "Withdrawn") {data = val} break
          case "PostPoned": if (val?.status !== "PostPoned") {data = val} break
          default: {data = " "} break
        }
        return data
    })
    
    const prop_Items = propStatus.filter(val => {
        if(val?.status == propertyStatus){
            return val;
        }
    })

    var PropItems = props?.department === "auction_residential" || props?.department === "auction_commercial" ? auction_Prop : prop_Items;

    //console.log("data ==>", propItems);

    const address = (data) => {
        var addr_commercial = [];
        if (data) {
            data?.address0 && addr_commercial.push(data?.address0);
            data?.address1 && addr_commercial.push(data?.address1);
            data?.address3 && addr_commercial.push(data?.address3);
            data?.address4 && addr_commercial.push(data?.address4);
            data?.postcode && addr_commercial.push(data?.postcode);
        }
        return  addr_commercial ? addr_commercial.toString(", ").replace(/,/g, ', ') : '';
      }

    const priceQualifier = (price_qualifier,extra) => {
        let value = (price_qualifier && extra == undefined) ? ` ${price_qualifier}` : '';
        return value;
    }

    const Introtext = (introTxt, title) => {
        let value = (introTxt == undefined && title) ? title : introTxt;
        return value;
    }

    return (
        PropItems?.length > 0 &&
        <React.Fragment>
            <section className="similar-properties">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                            <Col>
                                <h2>More properties like this</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="property-slider" {...settings}>
                                    {
                                        PropItems.map((item, index) => {
                                            
                                            if(index < 4){

                                                let uriStr = "";
                                                var price_pcm = "";

                                                if (item.search_type === "sales" && item.department === 'residential') {
                                                    uriStr = `residential-property-for-sale/`
                                                } else if (item.search_type === "lettings" && item.department === 'residential') {
                                                    uriStr = `residential-property-to-rent/`

                                                    price_pcm = "PCM";

                                                } else if (item.search_type === "sales" && item.department === 'commercial') {
                                                    uriStr = `commercial-property-for-sale/`
                                                } else if (item.search_type === "lettings" && item.department === 'commercial') {
                                                    uriStr = `commercial-property-to-rent/`
                                                } else if (item.search_type === "sales" && item.department === 'auction_residential') {
                                                    uriStr = `auction-residential-property-for-sale/`
                                                } else if (item.search_type === "sales" && item.department === 'auction_commercial') {
                                                    uriStr = `auction-commercial-property-for-sale/`
                                                }
                                                  
                                                return (
                                                    <div className="similar-properties-wrapper" key={index}>
                                                        <div className="img-zoom">
                                                            <Link to={`/${uriStr}${item?.slug}-${item?.id}/`} className="zoom">
                                                                <picture>
                                                                {
                                                                    item?.images[0]?.url ?                                                                    
                                                                    <ImageModule 
                                                                        ImageSrc={item?.images[0]} 
                                                                        altText={item?.display_address+ ' -  Strettons'} 
                                                                        ggfx_results={item?.ggfx_results} 
                                                                        imagename="property.images.detail" 
                                                                        strapi_id={item?.id} />
                                                                    :
                                                                    <img src={NoImage} alt="img" />
                                                                }
                                                                </picture>
                                                                
                                                                {/* <div className="gallery-info">
                                                                    <a href="javascript:void(0)">
                                                                        <i className="icon-gallery"></i>
                                                                        <span className="no-count">1/{item?.images?.length}</span>
                                                                    </a>
                                                                </div> */}

                                                                {
                                                                    item.status && item.status !== "Unsold" && 
                                                                    <div className="offer-text">
                                                                        { item.status.replace('ReferToAuctioneer', "Refer To Auctioneer")}
                                                                    </div>
                                                                }
                                                            </Link>
                                                            
                                                        </div>
                                                        <div className="property-details">

                                                            <h2 className="property-title equalHeights">
                                                                <Link to={`/${uriStr}${item?.slug}-${item?.id}`} className="link-title">
                                                                    {item?.address ?
                                                                        <>
                                                                            {item?.address?.address2 && HTMLReactParser(item.address.address2.toUpperCase() + `<br />`)}
                                                                            {item?.address && address(item.address)}
                                                                        </>
                                                                    : item?.display_address}
                                                                </Link>
                                                            </h2>

                                                            { (item?.introtext || item?.title) && <address className="address_title">{Introtext(item?.introtext, item?.title).substring(0, 50)} <Link to={`/${uriStr}${item?.slug}-${item?.id}`} className="link-descrip">View more</Link></address> } 
                                                            {
                                                                item?.department === "auction_residential" ||
                                                                item.department === "auction_commercial" ? (
                                                                    <>
                                                                    <div className="price">
                                                                        {item?.extra?.guidePrice && (
                                                                        <>
                                                                            <a><i className="icon-price-yellow"></i></a>
                                                                            {`Guide Price ${item?.extra.guidePrice}`}
                                                                        </>
                                                                        )}
                                                                    </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    {item?.price && (
                                                                        <div className="price">
                                                                        <a><i className="icon-price-yellow"></i></a>
                                                                        {item?.price
                                                                            ? item?.price?.toLocaleString() +
                                                                            (item?.price && item?.extra?.rentPriceMetric
                                                                                ? ` per ${item?.extra?.rentPriceMetric
                                                                                    .replace("sqft", "sq ft")
                                                                                    .replace("sqm", "sq m")}`
                                                                                : item?.price_qualifier
                                                                                ? priceQualifier(item?.price_qualifier, item?.extra)
                                                                                : "")
                                                                            : toTitleCase(props?.status) + " on Application"}
                                                                        </div>
                                                                    )}
                                                                    </>
                                                                )
                                                            }
                                                            
                                                            <SimilarPropertiesNegoDetails 
                                                                NegoDetails={item?.crm_negotiator_id}
                                                                NegoName={item?.crm_negotiator_id}
                                                                MemberPopup={props.MemberPopup}
                                                            />
                                                            
                                                        </div>
                                                    </div>
                                                )
                                            
                                            }
                                        })
                                    }
                                </Slider>
                            </Col>                        
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    )
}
export default SimilarProperties;