import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Form } from "react-bootstrap"
import {calculateMonthlyPayment, CalculatorFields} from "../../../../calculator/mortgage"
import {addCommas, numericWithDecimal, removeNonNumeric} from "../../../common/utils"
import {
    calculate,
    countries,
    // propertyTypes,
    // buyerTypes,
} from "@starberry/react-calculator-stampduty"
// Header component

const StampDuty = (props) => {

    const [calculator_value, setCalculatorValue] = useState({'purchase_price': props.calc_price, buyerType: 'first'});
    const[stampResult, setStampResult] = useState('');

    const propertyValue = calculator_value.purchase_price;
    const propertyType = props.property_type;
    const country = countries.ENGLAND;
    const buyerType = calculator_value.buyerType;

    useEffect(()=>{
        console.log("property_type", propertyValue,buyerType,propertyType);
        const stampDuty = calculate(
            propertyValue, propertyType, country, buyerType,
        );
        //console.log("stampDuty", stampDuty);
        setStampResult(stampDuty);
    },[propertyValue, buyerType, propertyType])


    const handleChange = (event, field_name) => {
        if(event.target.name === "buyerType"){
            setCalculatorValue({...calculator_value, [event.target.name]: event.target.value})
        } else{
            setCalculatorValue({...calculator_value, [event.target.name]: numericWithDecimal(event.target.value)})
        }
    }

    console.log("stampResult", stampResult)

    let effectiveRate = stampResult?.tax && (stampResult.tax / numericWithDecimal(propertyValue)) * 100
    effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)

    return (
        <React.Fragment>
            <Form className="form-wrapper">
                <Form.Group>
                    <Form.Label>purchase price</Form.Label>
                    <span className="currency">£</span>
                    <Form.Control type="text" name="purchase_price" value={addCommas(calculator_value.purchase_price)} onChange={handleChange} maxLength={CalculatorFields.PriceInputMax}/>
                </Form.Group>

                <Form.Group className="no_bdr full_wdth">
                    <Form.Check type="radio" name="buyerType" label={"I’m buying my first home or next home"} value={"first"} onChange={handleChange} checked={calculator_value.buyerType === "first" ? "checked" : ""} />

                    <Form.Check type="radio" name="buyerType" label={"I’m buying an additional property or second home"} onChange={handleChange} value={"investor"} checked={calculator_value.buyerType === "investor" ? "checked" : ""} />
                </Form.Group>
            </Form>
            <div className="form-details mt-0">
                <div className="rate">
                    <span className="sm-text ml-0">Stamp Duty</span> £{stampResult.tax > 0 ? addCommas(stampResult.tax) : 0} 
                    {stampResult.summaryBands && stampResult.summaryBands.length > 0 && <>
                        <br /><br />
                        <span>You have to pay stamp duty:</span><br/>
                        {stampResult.summaryBands.map((item, index) => {
                                return <>
                                    <span className="sm-text ml-0">{item.adjustedRate}% between £{item.start > 0 ?addCommas(item.start) : 0} and £{item.end ? addCommas(item.end) : 0}</span><br />
                                </>
                            })
                        }
                        <h4>Your effective stamp duty rate is {effectiveRate}% <br />{stampResult.comment}.</h4>
                    </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};
export default StampDuty;
