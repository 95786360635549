import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import {AllProperties, GetPropByCRM} from "../queries/common_use_query"

const PrevNextLot = (props) => {
    const[all_prop, setAllProp] = useState([]);

    // const { loading, error, data } = AllProperties(props?.department);
    const { data:prev_prop } = GetPropByCRM(props?.PrevID);
    const { data:next_prop } = GetPropByCRM(props?.NextID);

    // useEffect(()=>{
    //     setAllProp(data?.properties);
    // },[data])

    //console.log("department",  props?.current_id, props?.department, all_prop)

    // var get_current_index = all_prop?.length > 0 && Object.keys(all_prop).filter(key => all_prop[key].id === props?.current_id);

    // var next_id = get_current_index ? parseInt(get_current_index) + 1 : "";
    // var next_id_values = next_id ? all_prop?.length > 0 && all_prop[next_id] : "";

    // var prev_id = get_current_index ? parseInt(get_current_index) - 1 : "";
    // var prev_id_values = prev_id ? all_prop?.length > 0 && all_prop[prev_id] : "";
    var prev_id_values = prev_prop ? prev_prop?.properties.length > 0 && prev_prop?.properties[0] : "";
    var next_id_values = next_prop ? next_prop?.properties.length > 0 && next_prop?.properties[0] : "";

    //console.log("get_current_index", get_current_index, next_id, next_id_values, prev_id_values);

    var prev_base_url = "auction-residential-property-for-sale";
    if(prev_id_values?.department === "auction_commercial"){
        prev_base_url = "auction-commercial-property-for-sale";
    }

    var next_base_url = "auction-residential-property-for-sale";
    if(next_id_values?.department === "auction_commercial"){
        next_base_url = "auction-commercial-property-for-sale";
    }

    return (
        <>
            {
                prev_id_values?.id && 
                <li className="list-inline-item">
                    <div className={`enquiry d-md-flex ${props.auctionType === "future" ? "mt-2" : ""}`}>
                        <a href={`/${prev_base_url}/${prev_id_values?.slug}-${prev_id_values?.id}`} className="btn btn-primary-dark">
                            Previous Lot  
                        </a>
                    </div>
                </li>
            }

            {
                next_id_values?.id &&  
                <li className="list-inline-item">
                    <div className={`enquiry d-md-flex ${props.auctionType === "future" ? "mt-2" : ""}`}>
                        <a href={`/${next_base_url}/${next_id_values?.slug}-${next_id_values?.id}`} className="btn btn-primary">
                            Next Lot  
                        </a>
                    </div>
                </li>
            }            
        </>
    );
};
export default PrevNextLot;
