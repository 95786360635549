import React from "react";
import {Container} from "react-bootstrap";
import "./ContactVendor.scss";
const ContactVendor = ({ propertyVendor }) => {

    const [isValid, setIsValid] = React.useState(null);

    // Function to check if image exists
    const checkImage = (url, callback) => {
        const img = new Image();
        img.onload = () => callback(true);
        img.onerror = () => callback(false);
        img.src = url;
    };

    if (!propertyVendor || propertyVendor.length === 0) {
        return null;
    }

    const vendorContacts = propertyVendor.filter(element => element.ContactType === 'Vendor');

    if (vendorContacts.length === 0) {
        return null;
    }

    return (
        <div className="by-order-section">
            <Container>
                {vendorContacts.map((contact, index) => {
                    contact?.LogoUrl && checkImage(contact.LogoUrl,setIsValid); // Check and set image validity
                    const logoUrl = contact?.LogoUrl ? contact.LogoUrl : false;
                    const displayName = contact?.DisplayName || (contact?.Company ? `By Order Of ${contact.Company}` : null);                   
                    return (
                        <div key={index} className="vendor-info">
                            {displayName && <div className="display-name">{displayName}</div>}
                            {isValid && logoUrl && <div className="vendor-logo"><img src={logoUrl} alt={displayName} /></div>}  
                        </div>
                    );
                })}
            </Container>
        </div>
    );
};

export default ContactVendor;
