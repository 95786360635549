import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button, Breadcrumb } from "react-bootstrap"
import {People_Email, People_NegoID} from "../../../queries/common_use_query"
import { ImageModule } from "../../../modules/Image_Module";
import $ from "jquery";

// Negotiator details
const NegoDetails = (email_id) =>{

    const { loading, error, data } = People_Email(email_id);
  
    // console.log("nego_info ==>", data);
    return data;
}

const NegoDetailsAuction = (nego_id) =>{

    const { loading, error, data } = People_NegoID(nego_id);
  
    // console.log("nego_info =====>", data);
    return data;
}
// Negotiator details

const SimilarPropertiesNegoDetails = (props) => {
    
    // Negotiator details
    var nego_info = props?.NegoDetails && props?.NegoDetails[0]?.email && NegoDetails(props.NegoDetails[0].email);

    var nego_info_auction = props?.NegoName && NegoDetailsAuction(props?.NegoName);

    nego_info  = nego_info_auction || nego_info;
   // console.log("nego_info-----", nego_info_auction, nego_info);
    
    // Negotiator details

    useEffect(() => { 
        $("a").each(function() {
            if($(this)){
                var get_href = $(this).attr('href');
                var remove_unde = get_href ? get_href.replace('undefined','') : "";
                remove_unde && $(this).attr('href', remove_unde);
            }
        });
    }, [])
    
    return (
        nego_info?.peoples?.length > 0 &&
        <div className="contact-person mt-3">
            <div className="contact-img">
                <picture>
                    <ImageModule ImageSrc={nego_info?.peoples[0]?.Image} altText={nego_info?.peoples[0]?.Name + " - Strettons"} ggfx_results={nego_info?.peoples[0]?.ggfx_results} strapi_id={nego_info?.peoples[0]?.id} imagename="peoples.Image.team_avatar"  />                                         
                               
                </picture>
            </div>
            <div className="contact-info">
                <h3>{nego_info?.peoples[0]?.Name}</h3>
                <a href={"tel:" + nego_info?.peoples[0]?.Phone_Number} className="tel">
                    {nego_info?.peoples[0]?.Phone_Number}
                </a>
                <a href={"javascript:;"} onClick={()=>props.MemberPopup(nego_info?.peoples[0], "Member_Form")} className="mail"> / Email</a>
            </div>
        </div>
    )
}

export default SimilarPropertiesNegoDetails